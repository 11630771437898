import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddressService } from 'src/app/shared/service/address';
import { AddressModel } from '../model/address';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditAddressComponent } from '../edit-address/edit-address.component';
import { AccountService } from 'src/app/shared/service/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'address-component',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  addressList = new Array<AddressModel>();
  selectedShipmentAdressId;
  selectedInvoiceAdressId;
  isSameInvAddress = true;
  showEditAddressModal = false;

  @Input() calledPage; //çağılırdığı sayfa (checkout, myaccount)
  @Output() selectedAddresses: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public addressService: AddressService,
    public modalService: NgbModal,
    private accountService: AccountService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAddresses(true);
  }

  getAddresses(firstCall = false) {
    this.addressService.listAddress().subscribe(res => {
      this.addressList = res;

      if (firstCall) {
        if (this.addressList.length > 0) { //ilk çağırımda teslimat ve fatura adresi eşitlenir
          this.selectedShipmentAdressId = this.addressList[0].Id;
          this.selectedInvoiceAdressId = this.addressList[0].Id;
          this.syncAddresses();
        }
      }
    })
  }

  changeAddress(addressType, addressId) {
    if (this.isSameInvAddress) {
      this.selectedShipmentAdressId = addressId;
      this.selectedInvoiceAdressId = addressId;
    }
    else if (addressType == 's')
      this.selectedShipmentAdressId = addressId;
    else if (addressType == 'i')
      this.selectedInvoiceAdressId = addressId;

    this.syncAddresses();
  }

  deleteAddress(modal, addressId) {
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => { //evet hayır modalı açılır
      if (result == 'delete') {//modaldan 'delete' sonucu gelirse adres silme isteği yapılır
        this.addressService.deleteAddress(addressId).subscribe(res => {
          if (res.status) {//adres silindiyse
            this.toastr.success(res.description);
            this.getAddresses(true);//adresler tekrar çağırılır
          } else {//adres silenemediyse
            this.toastr.error(res.description);
          }
        })
      }
    }, (reason) => { });
  }

  changeSameInvAddress() {//fatura adresi farklı olsun/olmasın
    if (this.isSameInvAddress) {
      this.selectedInvoiceAdressId = this.selectedShipmentAdressId;

      this.syncAddresses();
    }
  }

  syncAddresses() {//Ebeveyn componente seçilen adres bilgisi gönderilir
    this.selectedAddresses.emit({ key: 'ShippingAddressId', value: this.selectedShipmentAdressId });
    this.selectedAddresses.emit({ key: 'BillingAddressId', value: this.selectedInvoiceAdressId });
  }

  editAddress(addressId = null) {
    var address = new AddressModel();
    if (addressId) {//mevcut adresi güncelle
      address = Object.assign({}, this.addressList.find(a => a.Id == addressId)); //mevcut adresin kopyası oluşturulu
    }
    else { //yeni adres ekle
      address.FaturaTuru = 0;
      address.contactId = this.accountService.getAccount.id;
    }

    //adres ekle/güncelle modal çağırılır
    let modalRef = this.modalService.open(EditAddressComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.showModal = true;
    modalRef.componentInstance.selectedAddress = address;

    modalRef.componentInstance.updatedAddress.subscribe((updatedAddress) => { //adres güncellenirse veya yeni adres eklenirse

      if (this.selectedShipmentAdressId == undefined || this.selectedInvoiceAdressId == undefined)
        this.getAddresses(true);
      else
        this.getAddresses();

    });
  }
}