import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-3d-pay-modal',
    templateUrl: './3dPay.modal.component.html',
    styleUrls: ['./3dPay.modal.component.scss']
})

export class ThreeDPayModalComponent { 
    @Input() payForm;
    constructor(
        private toastr: ToastrService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() { };

    getUrl() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.payForm);
    }
    
}