import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressModel } from '../model/address';
import { AddressService } from 'src/app/shared/service/address';
import { ToastrService } from 'ngx-toastr';
import { NgForm, NgControl } from '@angular/forms';


class Validation {
  name: string;
  error: string;
  isValidate: boolean
}

@Component({
  selector: 'edit-address-component',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss'],
})
export class EditAddressComponent implements OnInit {
  @Input() selectedAddress: AddressModel;
  @Output() updatedAddress = new EventEmitter<AddressModel>();


  constructor(public activeModal: NgbActiveModal,
    public addressService: AddressService,
    public modalService: NgbModal,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    //şehir, ilçe vb. bilgiler alınır
    if (this.selectedAddress) {
      this.listCity();
      if (this.selectedAddress !== null) {
        this.listTown(this.selectedAddress.CityId);
        this.listDistrict(this.selectedAddress.TownId);
        this.listQuarter(this.selectedAddress.DistrictId);
      }
    }
  }

  listCity() {
    this.addressService.listCities().subscribe(res => { });
  }

  listTown(cityId) {
    if (cityId !== 'undefined' && typeof cityId !== undefined && cityId != null)
      this.addressService.listTowns(cityId).subscribe(res => { });
  }

  listDistrict(townId) {
    if (townId !== 'undefined' && typeof townId !== undefined && townId != null)
      this.addressService.listDistrict(townId).subscribe(res => { });
  }

  listQuarter(districtId) {
    if (districtId !== 'undefined' && typeof districtId !== undefined && districtId != null)
      this.addressService.listQuarter(districtId).subscribe(res => { });
  }

  onChangeCity() {
    setTimeout(() => {
      this.listTown(this.selectedAddress.CityId);
      this.selectedAddress.TownId = undefined;
      this.selectedAddress.DistrictId = undefined;
      this.selectedAddress.NeighborhoodId = undefined;

      this.addressService.townList = [];
      this.addressService.districtList = [];
      this.addressService.quarterList = [];
    })
  }

  onChangeTown() {
    setTimeout(() => {
      this.listDistrict(this.selectedAddress.TownId);
      this.selectedAddress.DistrictId = undefined;
      this.selectedAddress.NeighborhoodId = undefined;

      this.addressService.districtList = [];
      this.addressService.quarterList = [];
    })
  }

  onChangeDistrict() {
    setTimeout(() => {
      this.listQuarter(this.selectedAddress.DistrictId);
      this.selectedAddress.NeighborhoodId = undefined;

      this.addressService.quarterList = [];
    })
  }

  saveAddress() {
    this.selectedAddress.FaturaTuru = parseFloat(this.selectedAddress.FaturaTuru);
    this.selectedAddress.CityId = this.selectedAddress.CityId;
    this.selectedAddress.TownId = this.selectedAddress.TownId;
    this.selectedAddress.TcKimlikNo = this.selectedAddress.TcKimlikNo;

    if (this.selectedAddress.DistrictId) {
      this.selectedAddress.DistrictId = this.selectedAddress.DistrictId;
    }

    if (this.selectedAddress.NeighborhoodId) {
      this.selectedAddress.NeighborhoodId = this.selectedAddress.NeighborhoodId;
    }

    if (this.selectedAddress.VergiNumarasi) {
      this.selectedAddress.VergiNumarasi = parseFloat(this.selectedAddress.VergiNumarasi);
    }

    this.addressService.saveAddress(this.selectedAddress).subscribe(res => {
      if (res.status) {
        this.updatedAddress.emit(this.selectedAddress);
        this.toastr.success(res.description);
        this.close("adres güncellendi");
      } else {
        this.toastr.error(res.description);
      }
    })
  }

  invoiceTypeChanged(invoiceType) {
    if (invoiceType == 1) {
      this.selectedAddress.VergiDairesi = "";
      this.selectedAddress.VergiNumarasi = "";
      this.selectedAddress.FirmaAdi = "";
    }
  }

  validateForm(addressForm: NgForm) {//false dönerse hata vardır
    if (addressForm.invalid) {
      return false;
    }

    for (const field in addressForm.controls) {
      if (!this.validateInput(addressForm.controls[field])) {
        return false;
      }
    }

    if (addressForm.controls.townId?.value == 'undefined'
      || addressForm.controls.cityId?.value == 'undefined'
      || addressForm.controls.districtId?.value == 'undefined'
      || addressForm.controls.neighborhoodId?.value == 'undefined') {
      return false;
    }

    if (!addressForm.controls.townId?.value
      || !addressForm.controls.cityId?.value
      || !addressForm.controls.districtId?.value
      || !addressForm.controls.neighborhoodId?.value) {
      return false;
    }

    return true;
  }

  validateInput(input) {//false dönerse hata vardır
    var res = true;

    if (input && input.touched) {
      if (input.invalid || (input.value === undefined || input.value == 'undefined'))
        res = false;
      else
        res = true;
    }

    return res;
  }

  close(reason) {
    // this.activeModal.close(this.getDismissReason(reason));
    this.activeModal.close();
  }

  openModal(content) {
    this.modalService.open(content, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }
}

