import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/shared/service/customer-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ecommerce-footer',
  templateUrl: './ecommerce-footer.component.html',
  styleUrls: ['./ecommerce-footer.component.scss']
})
export class EcommerceFooterComponent implements OnInit {
  settings = {};
  whatsapp = '';
  constructor(
    public customerService: CustomerService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.customerService.list().subscribe(res => { 
      this.convertString(res['data'][0].Baslik)
     });
    
    this.settings = JSON.parse(localStorage.getItem('settings')) || {};
    this.whatsapp = `whatsapp://send?phone=${this.settings['whatsapptel']}&abid=${this.settings['whatsapptel']}`;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  convertString(phrase) {
  
    var newUrl = phrase.toLowerCase();
    
    newUrl = newUrl.replace(/ö/g, 'o');
    newUrl = newUrl.replace(/ç/g, 'c');
    newUrl = newUrl.replace(/ş/g, 's');
    newUrl = newUrl.replace(/ı/g, 'i');
    newUrl = newUrl.replace(/ğ/g, 'g');
    newUrl = newUrl.replace(/ü/g, 'u');  


    newUrl = newUrl.replace(/[^a-z0-9\s-]/g, "");
    newUrl
    newUrl = newUrl.replace(/[\s-]+/g, " ");

    newUrl = newUrl.replace(/^\s+|\s+$/g,"");


    newUrl = newUrl.replace(/\s/g, "-");  

    return newUrl;
    
}

}
