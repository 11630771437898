import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { Options } from 'ng5-slider';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PriceComponent implements OnInit {
  @Output() priceFilters = new EventEmitter();
  @Input() options: Options;

  sliderForm: FormGroup = new FormGroup({
    sliderControl: new FormControl([100, 1000])
  });

  constructor() {   }

  ngOnInit() { }

  onUserChange(){
    this.priceFilters.emit(this.sliderForm.value.sliderControl);
  }

}
