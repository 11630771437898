export class AddressModel {
    public Id: number
    public contactId: string
    public AdresBaslik: string
    public AdiSoyadi: string
    public CityId: number
    public CityName: string
    public TownId: number
    public TownName: string
    public NeighborhoodId: number
    public NeighborhoodName: string
    public DistrictId: number
    public DistrictName: string
    public Adres: string
    public PostaKodu: number
    public CepTelefonu: string
    public FaturaTuru: any
    public TcKimlikNo: any
    public FirmaAdi: string
    public VergiDairesi: string
    public VergiNumarasi: any
  }
