import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountService } from './account.service';
import { Constants } from  '../constants';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private isCartEmpty:boolean;
  cart;
  cartSummary;
  favariteIconFlash = false;
  cartItemCount = new BehaviorSubject(0);
  constructor(
    private router: Router,
    private http: HttpClient,
    private accountService: AccountService
  ) {
    this.isCartEmpty = true
  }

  addCart(product, id): Observable<any> {
    try {
      product.ContactId = this.accountService.getAccount ? this.accountService.getAccount.id : 0;
      product.FirmaGuid = Constants.guid;
      product.uuid = localStorage.getItem('uuid');

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
          'Content-Type': 'application/json'
        })
      }
      return this.http.post(`${Constants.b2cApiUrl}/sepet/${Constants.platformId}/${Constants.apiId}/insert-or-update`, product, httpOptions)
      .pipe(map(res => {
        if (res['status']) {
            return res;
          } else {
            return res;
          }
        }))
    } catch (error) {
      console.log(error)
      let returnUrl = `product-detail/${id}`
      this.router.navigate(['login'], { queryParams: { returnUrl: returnUrl } })
    }
  }

  getCart(favorite?, couponCode?, kuponisle?) {
    let uuid = localStorage.getItem('uuid');
    let accountId = this.accountService.getAccount ? this.accountService.getAccount.id : 0;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }
    let url = `${Constants.b2cApiUrl}/sepet/${Constants.platformId}/${Constants.apiId}/list/${accountId}?uuid=${uuid}`;

    if (favorite) { url += `&favori=${favorite}` }
    if (couponCode) { url += `&kupons=${couponCode}` }
    if (kuponisle) { url += `&kuponisle=true` } else { url +="&kuponisle=false" }

    return this.http.get(url, httpOptions)
    .pipe(map(res => {
      if (res['result']['status']) {
        this.cart = res['data'];
          return res;
        } else {
          this.cart = [];
          return res;
        }
      }))
  }

  getCartSumamry(favorite?) {
    let uuid = localStorage.getItem('uuid');
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }
    let accountId = this.accountService.getAccount ? this.accountService.getAccount.id : 0;
    let url = `${Constants.b2cApiUrl}/sepet/ozet/${accountId}?uuid=${uuid}`;
    if (favorite) { url += `&favori=${favorite}` }
    return this.http.get(url, httpOptions)
      .pipe(map(res => {
        if (res['status']) {
          this.cartSummary = res['data'];
          return res;
        } else {
          this.cart = {};
          return res;
        }
      }))
  }

  deleteRow(guid) {
    let uuid = localStorage.getItem('uuid');
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }
    let accountId = this.accountService.getAccount ? this.accountService.getAccount.id : 0;
    return this.http.post(`${Constants.b2cApiUrl}/sepet/delete/${accountId}?uuid=${uuid}`, [guid], httpOptions)
      .pipe(map(res => {
        if (res['status']) {
          return res;
        } else {
          return res;
        }
      }))
  }

  changeCartItemStatus(row, status): Observable<any> {
    row.Favori = status;
    row.ContactId = this.accountService.getAccount.id;
    row.uuid = localStorage.getItem('uuid');
    row.FirmaGuid = Constants.guid;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(`${Constants.b2cApiUrl}/sepet/${Constants.platformId}/${Constants.apiId}/insert-or-update`, row, httpOptions)
      .pipe(map(res => {
        if (res['status']) {
          return res;
        } else {
          return res;
        }
      }))
  }
}
