import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'; 
import { ScrollToModule } from 'ng2-scroll-to-el';
import { SharedModule } from './shared/shared.module';

import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import { LOCALE_ID } from '@angular/core';

registerLocaleData(localeTr);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ScrollToModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "tr-TR" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
