import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-all-filters',
  templateUrl: './all-filters.component.html',
  styleUrls: ['./all-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AllFiltersComponent implements OnInit, OnChanges {

  @Input() attributes;
  @Input() options: Options;
  @Output() checkedAttributes: EventEmitter<any> = new EventEmitter<any>();

  sliderForm: FormGroup = new FormGroup({
    sliderControl: new FormControl([100, 1000])
  });

  checkedTags = [];

  constructor() { }

  ngOnInit(): void {
    this.attributes.forEach(att => {
      att['Values'] = att['Values'].filter(a => {
        return a['Value'] ? true : false;
      });
    });

    this.attributes.sort(function (a, b) {
      return a['Name'].localeCompare(b['Name']);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['attributes'] !== undefined)
      this.ngOnInit();
  }

  changeSearchFilter(event, checkedFilter) {
    var changedFilter = this.checkedTags.find(item => item.Name == checkedFilter);

    if (changedFilter === undefined) {
      this.checkedTags.push({ Name: checkedFilter, values: [event.target.value] });
    }
    else {
      let index = changedFilter.values.indexOf(event.target.value);
      if (event.target.checked)
        changedFilter.values.push(event.target.value);
      else
        changedFilter.values.splice(index, 1);
    }

    this.checkedAttributes.emit(this.checkedTags);
  }

  onUserChange() {
    var priceFilter = this.checkedTags.find(item => item.Name == 'FİYAT');

    var selectedPrices = this.sliderForm.value.sliderControl;
    var value = [`${selectedPrices[0]};${selectedPrices[1]}`];

    if (priceFilter) {
      priceFilter.values = value;
    }
    else {
      this.checkedTags.push({ Name: 'FİYAT', values: value });
    }

    this.checkedAttributes.emit(this.checkedTags);
  }

  cleanFilter(attributeName: string = '', cleanAll = false) {
    if (attributeName) {
      let index = this.checkedTags.findIndex(x => x.Name === attributeName);
      if (index > -1) {
        this.checkedTags.splice(index, 1);
      }

      var filter = this.attributes.find(item => item.Name == attributeName);
      if (filter) {
        filter.Values.forEach(element => {
          const ele = document.getElementById(element.Value) as HTMLInputElement;
          ele.checked = false;
        });
      }
    }
    else if (cleanAll) {
      this.checkedTags = [];

      this.attributes.forEach(att => {
        att.Values.forEach(element => {
          const ele = document.getElementById(element.Value) as HTMLInputElement;
          if (ele) {
            ele.checked = false;
          }
        });
      });
    }

    this.checkedAttributes.emit(this.checkedTags);
  }

  isSelected(attributeName: string = '') {
    var res = false;

    if (attributeName) {
      var filter = this.checkedTags.find(item => item.Name == attributeName);
      if (filter && filter.values.length > 0) {
        res = true;
      }
    }
    else {
      if (this.checkedTags.length > 0)
        res = true;
    }

    return res;
  }

  deleteFilter(attributeName, filterName) {
    var filter = this.checkedTags.find(item => item.Name == attributeName);

    let attributeIndex = this.checkedTags.findIndex(att => att.Name === attributeName);

    if (filter) {
      let index = filter.values.findIndex(x => x === filterName);

      filter.values.splice(index, 1);
      if (filter.values.length == 0)
        this.checkedTags.splice(attributeIndex, 1);

      var attribute = this.attributes.find(item => item.Name == attributeName);
      if (attribute) {
        attribute.Values.forEach(element => {
          if (element.Value == filterName) {
            const ele = document.getElementById(element.Value) as HTMLInputElement;
            if (ele) {
              ele.checked = false;
            }
          }
        });
      }

      this.checkedAttributes.emit(this.checkedTags);
    }
  }
}