import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  
  companyInfos = {
    name: 'Kurumsal Reyon',
    contact: {
      address: 'Yenigün Mah. 1303 Sok. No:2/54 Konak/İZMİR',
      phones: [
        {
          title: 'İletişim',
          no: '0555 535 74 48'
        }
      ],
      fullName: 'Destech Bilişim Teknoloji Hizm. Tic. Ltd. Şti.',      
      fax: '',
      email: 'info@kurumsalreyon.com'
    },
    social: [
      // {
      //   icon: 'fa fa-twitter',
      //   link: 'https://twitter.com'
      // }
    ]
  }
  theme = {
    type: 'shop', // shop - gallery
    title: 'Kurumsal Reyon',
    logo: {
      selected: 'text', // text - image
      text: 'Kurumsal Reyon',
      headerLogo: 'assets/images/logo/kurumsal-reyon-logo.png',
      footerLogo: 'assets/images/logo/kurumsal-reyon-logo-white.png'
    },
    favicon: 'assets/images/icon/kurumsal-reyon-favicon.png',
    maintenance: {
      active: false,
      leftImage: 'assets/images/logo/altay-maintenance.jpg'
    },
    showNoStock: 1,
    learnshow:1
  }

  constructor() { }

}
