import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Constants } from '../constants';

@Injectable()
export class CompanyService {
    constructor(
        private http: HttpClient
    ){ }

    getCompanies(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        let data = { 
            "FirmaGuid": Constants.guid, 
            "Skip": 0, "Take": 20, 
            "Search": "", 
            "Order": "Id", 
            "OrderType": "Artan" 
        }
        
        let requestUrl = `${Constants.b2bApiUrl}/company/list`
        return this.http.post(requestUrl, data, httpOptions)
        .pipe(map(res => {
            if (res['result']['status']) {
                return res['data']
            }
        }))
    }

}