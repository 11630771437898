import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { Constants  } from '../constants';
import { SettingsService } from './settings.service';

@Injectable()
export class ProductsService {
    productList:Array<any>;
    pager:any;
    modalImages;
    public filterBar: boolean = false;
    constructor(
        private http: HttpClient,
        private accountService: AccountService,
        private settingsService: SettingsService
    ){ }

    list(searchCriteria, skip, take, hUrl, order?):Observable<any> {
        let requestPayload = {
            ApiId: Constants.apiId,
            FirmaGuid: Constants.guid,
            PlatformId: Constants.platformId,
            //KoleksiyonId: searchCriteria.collectionId,
            Handleurl:hUrl,
            Search: searchCriteria.search,
            Filter: { attributes: searchCriteria.filters },
            Skip: skip,
            Take: take,
            Order: order,
            QuantityStatus: this.settingsService.theme.showNoStock,
            CompanyId: this.accountService.getAccount ? this.accountService.getAccount['CompanyId'] : 0
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.b2cApiUrl}/mobil/product/list`, requestPayload, httpOptions)
        
        .pipe(map(res => {
            if (res['result']['status']){
                this.productList = res['data'];
                this.pager = res['pagingData'];
                return this.productList;
            }
        }))
    }

    getProductDetail(id, handleUrl = "") {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        
        return this.http.get(`${Constants.b2cApiUrl}/mobil/product/detail/${Constants.platformId}/${Constants.apiId}/${id}?handleUrl=${handleUrl}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.modalImages = res['data']['image'];
                    return res['data'];
                }
            }))
    }

    getProductSizeTable(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        
        return this.http.get(`${Constants.b2cApiUrl}/urun-beden-tablosu/${id}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    return res['data'];
                }
            }))
    }

    getProductAttributes(stockItems) {
        stockItems.sort((a: any, b: any) => {
            if (a.order < b.order) {
                return -1;
            } else if (a.order > b.order) {
                return 1;
            } else {
                return 0;
            }
        });
        let attributes = [];
        for (let stock of stockItems) {
            for (let attribute of stock.stockItemAttribute) {
                let propertie = { name: attribute.name, values: [{ value: attribute.value, amount: stock.quantity}] };
                let temp = attributes.find(a => { return a.name == attribute.name });
                if(temp) {
                    let duplicateValue = temp.values.find(v => { return v.value == attribute.value })
                    if (!duplicateValue){
                        temp.values.push({ value: attribute.value, amount: stock.quantity });
                    }
                }else {
                    attributes.push(propertie);
                }
            }
        }

        return attributes;
    }

    getFilteredStockItems(stockItems, attName, attValue) {
        let filteredStockItems = [];
        for (const si of stockItems) {
            for (const sat of si.stockItemAttribute) {
                if(sat.name == attName && sat.value == attValue) {
                    filteredStockItems.push(si);
                }
            }
        }

        return filteredStockItems;
    }

    findStockItem(stockItem, attributes) {
        let item_guid = '';
        
        if (Object.entries(attributes).length === 0 && attributes.constructor === Object) {
            item_guid = stockItem[0].ItemGuid;
        }else {
            for (const stock of stockItem) {
                let valid = 0;
                for (const stockAttribute of stock.stockItemAttribute) {
                    for (const key in attributes) {
                        if (attributes.hasOwnProperty(key)) {
                            if (stockAttribute.name == key && stockAttribute.value == attributes[key]) {
                                valid++;
                            }
                        }
                    }
                    if (valid == Object.keys(attributes).length) {
                        item_guid = stock.ItemGuid;
                    }
                }
            }
        }

        return item_guid;
    }

    getSmartSearchParameters(searchKey) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.b2cApiUrl}/mobil/${Constants.platformId}/${Constants.apiId}/search?skip=0&take=10&companyId=${this.accountService.getAccount['CompanyId']}`, { search: searchKey }, httpOptions)
            .pipe(map(res => {
                if (res['result']['status']) {
                    return res['data'];
                }
            }))
    }
}