import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Constants } from '../constants';

@Injectable()
export class CustomerService {
    csList:Array<any>;
    constructor(
        private http: HttpClient
    ){ }

    list():Observable<any> {
        let  requestPayload = {
            //ApiId :   this.apiId,
            FirmaGuid: Constants.guid,
            //PlatformId: this.platformId,
            Skip: 0,
            Take: 100
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.b2cApiUrl}/musteri-hizmetleri/list`, httpOptions)
        .pipe(map(res => {
            if (res['status']) {
                this.csList = res['data']
                return res
            }else {
                this.csList =  [];
                return res;
            }
        }))
    }

    getDetail(id) {
        return this.csList.find(c => { return c.Id == id});
    }
}