import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { AccountService } from '../service/account.service'
import { AppService } from '../service/app.service';

@Injectable()

export class AuthGuard implements CanActivate {
    setting = {};
    constructor(
        private accountService: AccountService, 
        private router:Router,
        private appService: AppService) {}
        
    async canActivate(next: ActivatedRouteSnapshot, state:RouterStateSnapshot) {
        let logged = this.accountService.isloggedIn;
        // console.log(this.setting['activateLoginGuard'] == true);
        if (this.setting == null) {
            this.setting = await this.getSettings();
        }
        if (this.setting['activateLoginGuard']) {
            if (logged) {
                return true
            }else {
                this.router.navigate(['giris'], { queryParams: { returnUrl: state.url } })
                return false
            }
        }else {
            if (logged) {
                return true
            }else {
                //console.log(state.url)
                if (
                    // state.url.indexOf('odeme') > 0 ||
                    // state.url.indexOf('siparis') > 0 ||
                    // state.url.indexOf('hesabim') > 0
                    state.url.indexOf('giris') < 0
                ){
                    this.router.navigate(['giris'], { queryParams: { returnUrl: state.url } })
                    return false
                }else {
                    return true;
                }
            }
        }
    }

    getSettings() {
        return new Promise((resolve, reject) => {
            this.appService.settings().subscribe(res => {
                resolve(res);
            })
        })
    }
}