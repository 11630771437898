import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Constants } from '../constants'
@Injectable()
export class LoginService {
    constructor(
        private http: HttpClient 
    ){ }
    
    sendVerificationCode(registerType:string, identityValue:string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.gloparkApiUrl}/member-auth-code`
            , {
                IletisimTipi: registerType,
                Iletisim: identityValue
            }, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }

    verifyCode(registerType, identityValue, verificationCode, code, secret = false, condition = false, token?): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.gloparkApiUrl}/member-auth`
            , {
                IletisimTipi: registerType,
                Iletisim: identityValue,
                DogrulamaKodu: verificationCode,
                FirmaKodu: code,
                Token: token,
                Gizlilik: secret,
                KullanimKosul: condition
            }, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }



}