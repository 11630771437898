import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../../../service/collection.service';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from 'src/app/shared/service/filter.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
  collections;
  collections2 = [];
  constructor(
    public filterService:FilterService,
    public collectionService: CollectionService
    ) { 
      this.collections = [];
    }
  
  // collapse toggle
  ngOnInit() { 
    this.collectionService.collectionListDetector.subscribe(res => {
      // if(res) {
      //   if (res.Children && res.Children.length > 0) {
      //     this.collections = res.Children;
      //     this.collectionService.collectionChangeDetector.next(res);
      //   }else {
          // this.collectionService.collectionChangeDetector.next(res);
      //   }
      // }else {
      //   this.getCollections();
      // }
    }) 
     this.getCollections();
  }

  // For mobile view
  public mobileFilterBack() {  }
 
  toggleFilter(){
    this.filterService.filterBar = !this.filterService.filterBar
  }

  getCollections() {
    this.collectionService.listTree().subscribe(res => {
      this.collections = res;
      res.forEach(element => {
          if(element.Item.GosterimYeri == '0'){
            this.collections2.push(element);
          }
      });
    });
  }

  changeCollection(collection)  {
    this.collectionService.collectionChangeDetector.next(collection.Item);
  }
   
}
