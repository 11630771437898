import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Constants } from '../constants'

@Injectable()
export class AppService {
    constructor(
        private http: HttpClient
    ){ }
    settings():Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.gloparkApiUrl}/app-settings/${Constants.platformId}/${Constants.apiId}`, httpOptions)
        .pipe(map(res => {
            if (res['status']) {
                localStorage.setItem('settings', JSON.stringify(res['data']));
                return res['data'];
            }else {
                return []
            }
        }))
    }


}