import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '../../../service/e-commerce/products.service';
import { CartService } from '../../../service/e-commerce/cart.service';
import { WishListService } from '../../../service/e-commerce/wish-list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuickPreviewModalComponent } from './quick-preview-modal/quick-preview-modal.component';
import { AccountService } from 'src/app/shared/service/account.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from 'src/app/shared/constants';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/shared/service/settings.service';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit {
  learnshow;

  @Input() products: any;
  link;
  maths;
  constructor(
    public productsService: ProductsService,
    private modalService: NgbModal,
    private cartService:CartService,
    private wishlistService:WishListService,
    private accountService: AccountService,
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    private settingsService:SettingsService
  ) {}

  ngOnInit() { 
    this.learnshow = this.settingsService.theme.learnshow;
    
    let result = ((this.products.price - this.products.displayPrice) / this.products.price) * 100;
    this.maths = parseInt(String(result))

  }

  requestPrice(productId) {
    if(this.accountService.getAccount) {
      var contactId = this.accountService.getAccount.id;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': Constants.Authorization,
          'Content-Type': 'application/json'
        })
      }
      this.http.get(`${Constants.b2cApiUrl}/fiyatiste/${Constants.platformId}/${Constants.apiId}/${contactId}/${productId}`, httpOptions)
      .subscribe(arg => {
        arg['result'] ? this.toastr.success("Fiyat isteme işlemi başarılı") : this.toastr.error("Fiyat istenirken bir hata oluştu");
      }, err => {
        this.toastr.error('Bir hata ile karşılaşıldı');
        console.error('Fiyat İste Hatası', err);
      });

    }
    else {
      this.router.navigateByUrl('giris');
    }
  }

  openQuickPreview(id) {
    let modalRef = this.modalService.open(QuickPreviewModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.productId = id;

    modalRef.result.then(result => { console.log(result) }).catch(err => { /*console.log(err)*/ });
  }

  public addToCart(product: any, quantity: number = 1) {
    this.cartService.addToCart(product, quantity);
  }

  public addToCompare(product: any) {
    this.productsService.addToCompare(product);
  }

  // Add to wishlist
  public addToWishlist(product: any) {
    this.wishlistService.addToWishlist(product);
  }

}
