import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { Constants } from '../constants';

@Injectable()
export class AddressService {
    addressList:Array<any>;
    cityList: Array<any>;
    townList: Array<any>;
    districtList: Array<any>;
    quarterList: Array<any>;

    constructor(
        private http: HttpClient,
        private accountService: AccountService
    ){ }

    listCities(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.pureB2cApiUrl}/sehir`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.cityList = res['data'];
                    return this.cityList;
                } else {
                    this.cityList = [];
                    return this.cityList;
                }
            }))
    }

    listTowns(cityId): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.pureB2cApiUrl}/ilce/${cityId}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.townList = res['data'];
                    return this.townList;
                } else {
                    this.townList = [];
                    return this.townList;
                }
            }))
    }

    listDistrict(townId): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.pureB2cApiUrl}/semt/${townId}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.districtList = res['data'];
                    return this.districtList;
                } else {
                    this.districtList = [];
                    return this.districtList;
                }
            }))
    }

    listQuarter(districtId): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.pureB2cApiUrl}/mahalle-koy/${districtId}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.quarterList = res['data'];
                    return this.quarterList;
                } else {
                    this.quarterList = [];
                    return this.quarterList;
                }
            }))
    }

    listAddress(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.b2cApiUrl}/musteri-adres/${this.accountService.getAccount.id}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.addressList = res['data'];
                    return this.addressList;
                } else {
                    this.addressList = [];
                    return this.addressList;
                }
            }))
    }


    saveAddress(address): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.post(`${Constants.b2cApiUrl}/musteri-adres/insert-or-update`, address, httpOptions)
            .pipe(map(res => {
                return res;
            }))
    }

    deleteAddress(address_id): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.post(`${Constants.b2cApiUrl}/musteri-adres/delete`, parseFloat(address_id), httpOptions)
            .pipe(map(res => {
                return res;
            }))
    }
}