<h3 class="box__subTitle" *ngIf="calledPage == 'checkout'">TESLİMAT ADRESİ</h3>
<div class="addressBoxHolder">
    <div class="addressBox" *ngFor="let address of addressList; let i = index"
        [ngClass]="{'isActive': calledPage == 'checkout' && selectedShipmentAdressId == address.Id}"
        (click)="changeAddress('s',address.Id)">
        <input id="shipping{{address.Id}}" class="customRadio" type="radio" name="deliveryAddress"
            [(ngModel)]="selectedShipmentAdressId" [value]="address.Id">
        <label [ngClass]="{'label': calledPage == 'checkout'}" for="shipping{{address.Id}}">
            <address class="address">
                <p class="address__name"><b>{{address.AdresBaslik}} - </b>{{address.AdiSoyadi}}</p>
                <p class="address__detail">{{address.Adres}}</p>
                <p><b>Fatura: </b> {{address.FaturaTuru === 0 ? 'Bireysel':'Kurumsal'}}</p>
                <p><b>Tel: </b> {{address.CepTelefonu}}</p>
            </address>
        </label>

        <div class="statusBtn">
            <span class="statusBtn__update" (click)="editAddress(address.Id)">Düzenle</span>
            <span class="rowSep"></span>
            <span class="statusBtn__delete" (click)="deleteAddress(content, address.Id)">Sil</span>
        </div>
        <span class="greenTriangle svgIcon svgIcon_tick-white"
            *ngIf="calledPage == 'checkout' && selectedShipmentAdressId == address.Id"></span>
    </div>
    <div class="addressBox isEmpty js-newAddBoxBtn" (click)="editAddress()">
        <i class="svgIcon svgIcon_plus"></i>
        <p class="info">
            <span class="link">Yeni Adres Ekle</span>
        </p>
    </div>

    <!-- <div class="addressBox isEmpty isDisabled hidden">
        <p class="info">
            <span class="link">Yeni Adres Ekle</span>
        </p>
        <small class="detail">En fazla 10 adres ekleyebilirsiniz. Yeni adres eklemek için birini silin.</small>
    </div> -->
</div>

<!--Eğer component ödeme sayfasında çağırıldıysa görüntülenecek-->
<div *ngIf="calledPage == 'checkout'">
    <h3 class="box__subTitle">FATURA ADRESİ</h3>
    <div class="row sameAddress">
        <input type="checkbox" [(ngModel)]="isSameInvAddress" id="syncAddress" class="customCheckbox"
            (ngModelChange)="changeSameInvAddress()">
        <label for="syncAddress" class="svgIcon checkbox-label">Faturaya teslimat adresi yazılsın.</label>
    </div>
    <div *ngIf="!isSameInvAddress">
        <div class="addressBoxHolder">
            <div class="addressBox" *ngFor="let address of addressList; let i = index"
                [ngClass]="{'isActive':  calledPage == 'checkout' && selectedInvoiceAdressId == address.Id}"
                (click)="changeAddress('i',address.Id)">
                <input id="billing{{address.Id}}" class="customRadio" type="radio" name="invoiceAddress"
                    [(ngModel)]="selectedInvoiceAdressId" [value]="address.Id">
                <label [ngClass]="{'label': calledPage == 'checkout'}" for="shipping{{address.Id}}"
                    for="billing{{address.Id}}">
                    <address class="address">
                        <p class="address__name"><b>{{address.AdresBaslik}} - </b>{{address.AdiSoyadi}}</p>
                        <p class="address__detail">{{address.Adres}}</p>
                        <p><b>Fatura: </b> {{address.FaturaTuru === 0 ? 'Bireysel':'Kurumsal'}}</p>
                        <p><b>Tel: </b> {{address.CepTelefonu}}</p>
                    </address>
                </label>

                <div class="statusBtn">
                    <span class="statusBtn__update" (click)="editAddress(address.Id)">Düzenle</span>
                    <span class="rowSep"></span>
                    <span class="statusBtn__delete" (click)="deleteAddress(content, address.Id)">Sil</span>
                </div>
                <span class="greenTriangle svgIcon svgIcon_tick-white"
                    *ngIf="calledPage == 'checkout' && selectedInvoiceAdressId == address.Id"></span>
            </div>
            <div class="addressBox isEmpty js-newAddBoxBtn" (click)="editAddress()">
                <i class="svgIcon svgIcon_plus"></i>
                <p class="info">
                    <span class="link">Yeni Adres Ekle</span>
                </p>
            </div>

            <!-- <div class="addressBox isEmpty isDisabled hidden">
                <p class="info">
                    <span class="link">Yeni Adres Ekle</span>
                </p>
                <small class="detail">En fazla 10 adres ekleyebilirsiniz. Yeni adres eklemek için birini silin.</small>
            </div> -->
        </div>
    </div>
</div>


<ng-template #content let-modal>
    <div class="modal-content" style="padding: 20px;">
        <div class="modal-header">
            <button aria-label="Close" class="close" type="button" (click)="modal.close('cancel')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <h3 class="m-b-10">Adresi Sil</h3>
            <p style="padding-bottom:20px;">Bu adresin silinmesini onaylıyor musunuz?</p>
            <button class="btn btn-danger radius-0 float-right" (click)="modal.close('delete')">Evet</button>
            <button class="btn btn-outline-secondary radius-0 float-right mr-2"
                (click)="modal.close('cancel')">Hayır</button>
        </div>
    </div>
</ng-template>